import ArticleList from '../components/Article/ArticleList'
import BlogTagList from '../components/Blog/BlogTagList'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from "react-bootstrap/Container"
import Link from 'next/link'
import indexStyles from '../styles/Index.module.css'
import { ArrowRight } from 'react-bootstrap-icons'
import CourseInfoCard from '../components/Course/CourseInfoCard'
import { useAuth } from '../contexts/Auth'
import { supabase } from "../utils/supabaseClient"
import Meta from '../components/Meta'
import { useEffect } from 'react' 


export async function getStaticProps() {
  let { data: articles, error } = await supabase
    .from("main_article")
    .select(`
      id,
      article_status, article_title, article_published, article_content, article_image, article_slug, reading_time,
      main_profile!main_article_profile_fkey (
        username,
        user_image
      ),
      main_tag (
       id, 
       tag_name
     )`
     )
    .filter('article_status', 'eq', 'published')
    .order('article_published', { ascending: false })
    .limit(15)

  error && console.log(error)

  for (let a of articles) {
    if (a.article_content.substring(0,150).includes("strong")) {
      a.article_content = a.article_content.substring(a.article_content.indexOf("</strong>")+9).replace( /(<([^>]+)>)/ig, '').substring(0,160)
    } else {
      a.article_content = a.article_content.replace( /(<([^>]+)>)/ig, '').substring(0,150)
    }
  }


  const { data: article_tags, error1 } = await supabase 
    .from("main_tag")
    .select('id, tag_name, tag_slug')
    .filter('tag_type', 'eq', 'article')

  return {
    props: {
      articles,
      article_tags,
    },
    revalidate: 120,
  };
}

export default function Page(props) {
  const { user } = useAuth()
  useEffect(() => {
    window.ezstandalone = window.ezstandalone || {};
    ezstandalone.cmd = ezstandalone.cmd || [];
    ezstandalone.cmd.push(function() {
        ezstandalone.define(105);
        if (!ezstandalone.enabled) {
        ezstandalone.enable();
        ezstandalone.display();
        } else {
          ezstandalone.refresh();
        }
    });
  },[])

  return (
    <>
      <Meta />
      <Container fluid className={indexStyles.cta}>
        <Container>
          <h1 className={indexStyles.title}>Code, Write, Learn</h1>
          <h5 className={indexStyles.subtitle}>Coding can be difficult. Join us on our journey.</h5>
          <Link href="/publish" passHref>
            <a>
              <Button variant="white">Start Writing</Button>
            </a>
          </Link>
          <br/><br/>
        </Container>
      </Container>

      <Container className="py-5">
        <Row>
          {/* Column 1 */}
          <Col md={12} lg={4} className="mb-4">
            <div className={indexStyles.tag_col}>
              <BlogTagList tags={props.article_tags} />
            

              {/* if no user, show Course info component */}
              {!user && <CourseInfoCard />}
              <div id="ezoic-pub-ad-placeholder-105"> </div>
            </div>
            
          </Col>
          {/* Column 2 */}
          <Col md={12} lg={8} className="mb-4">
            <ArticleList articles={props.articles} pubProfileToggle={true} />
            <div className="text-end">
            <Link href="/blog/articles" passHref>
              <Button variant="link">View more <ArrowRight /></Button>
            </Link>
            </div>
          </Col>
        </Row> 
      </Container>
    </>
  )
}
