import articleStyles from '../../styles/Article.module.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import changeDateFormat from '../utils'
import Image from 'next/image'
import { cloudfront } from '../../config'
import Link from 'next/link'


export default function ArticlePubProfile(props) {
    return (
        <Row>
            <Col className="my-auto">
                <p className={articleStyles.published}>
                    {changeDateFormat(props.published)}
                </p>
            </Col>
            {props.pubProfileToggle &&
            <Col className="my-1">
                    <div className="d-flex justify-content-end">
                        {props.profile.user_image && 
                            <Image 
                                className={articleStyles.author_image} 
                                src={`${cloudfront}/${props.profile.user_image}`} 
                                width={25} 
                                height={25} 
                                objectFit="cover" 
                                alt={props.profile.username} 
                            />
                        }
                        <Link href={`/blog/${props.profile.username}`} passHref>
                            <small className={articleStyles.userpage_link}>
                                {props.profile.username}
                            </small>
                        </Link>
                    </div>
            </Col>}
        </Row>
    )
}