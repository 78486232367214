import Button from 'react-bootstrap/Button'
import Link from 'next/link'

export default function BlogTagItem(props) {
    return(
        <Link href={`/blog/${props.tag.tag_slug}`} as={`/blog/${props.tag.tag_slug}`} passHref>
            <Button className="m-1" variant="purple" size="sm">{props.tag.tag_name}</Button>
        
        </Link>
        
    )
}