import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Key, Unlock } from "react-bootstrap-icons";
import courseStyles from '../../styles/CourseDetail.module.css'
import Link from 'next/link'
import { cloudfront } from "../../config";
import Image from 'next/image'

//imported in pages/index.js
export default function CourseInfoCard() {
    return (
        <Card className="mx-1 mt-4 shadow-sm">
            <Image 
                className={`card-img-top ${courseStyles.course_thumbnail}`} 
                variant="top" 
                src={`${cloudfront}/django_web_app/encore_homepage.PNG`} 
                width={356} 
                height={200} 
                layout="intrinsic" //Scale down to fit width of container
                objectPosition="center top"
                alt="Building a Django Web App"/>
            <Card.Body>
                    <h5 className="text-uppercase mx-3 my-2">Building a Django Web App</h5>
                    <div className={courseStyles.thumbnail_info}>
                        <p><Unlock className="pr-2"/> All content unlocked from the start</p>
                        <p><Key className="pr-2"/>  Unlimited access at no cost</p>
                    </div>
                    <div className="d-grid gap-2">
                        <Link href="/build-a-django-web-app" passHref>
                            <a className="d-grid gap-2 text-deco">
                                <Button variant="purple">
                                    Get Started
                                </Button>
                            </a>
                        </Link>
                        <Link href="/course-details" passHref>
                            <a className="d-grid gap-2 text-deco">
                                <Button variant="link">
                                    View Course Details
                                </Button>
                            </a>
                        </Link>
                    </div>
            </Card.Body>
        </Card>
    )
}