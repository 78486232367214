import BlogTagItem from './BlogTagItem'
import Button from 'react-bootstrap/Button'
import Link from 'next/link'

export default function BlogTagList(props) {
    return(
        <>
            {props.tags.map(tag => (<BlogTagItem key={tag.id} tag={tag} />))}
            <Link href="/blog/articles" passHref>
              <Button className="m-1" variant="purple" size="sm">View All</Button>
            </Link>
        </>
    )
}