import Link from 'next/link'
import Image from 'next/image'
import articleStyles from '../../styles/Article.module.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ArticleTagsRead from './ArticleTagsRead'
import ArticlePubProfile from './ArticlePubProfile'
import {cloudfront} from '../../config'


export default function ArticleItem(props) {
    return(
        <>
            <Link href={`/blog/article/${props.article.article_slug}`} passHref>
                <a className={articleStyles.link}>
                    <Row xs={1} md={2}>
                    <Col className="my-auto" lg={4}>
                        <Image 
                            className={articleStyles.thumbnail_image} 
                            src={`${cloudfront}/media/${props.article.article_image}`} 
                            width={213} height={150} 
                            layout='responsive' 
                            alt={props.article.article_title} 
                        />
                    </Col>
                    <Col className="my-auto" lg={8}>
                        <ArticlePubProfile
                            published={props.article.article_published} 
                            profile={props.article.main_profile} 
                            pubProfileToggle={props.pubProfileToggle}
                        />
                        <h5 className={articleStyles.thumbnail_title}>{props.article.article_title}</h5>
                        <p className={articleStyles.thumbnail_description}>
                            {props.article.article_content}...
                        </p>
        
                        <ArticleTagsRead
                            article_tags={props.article.main_tag} 
                            reading_time={props.article.reading_time}
                        />
                        
                    </Col>
                    </Row>
                </a>
            </Link>
            <br /> <br />
        </>
    )
}